export default {
    getContactLink: function(userInfo) {
        if (!userInfo) return '/contact'

        const fields = [
            {name: 'fname', value: userInfo.givenName},
            {name: 'lname', value: userInfo.surname},
            {name: 'mailto', value: userInfo.mail},
            {name: 'company', value: userInfo.organization},
            {name: 'title', value: userInfo.jobTitle},
            {name: 'phone', value: userInfo.mobilePhone},
            {name: 'country', value: userInfo.country},
        ]

        var urlParams = []

        fields.forEach(field => {
            urlParams.push(`${field.name}=${field.value}`)
        })

        return encodeURI(`/contact?${urlParams.join('&')}`)
    },
}
<template>
  <v-footer
    dark
    style="background-color: var(--eastman-azure); padding: 1.2em 10vw; height: 64px;"
  >
    <!-- <span class="mr-2">
      Developed by
    </span> -->
    <v-img src="@/assets/Eastman_White_72dpi.png" max-height="15" max-width="128" contain></v-img>
    <a
      class="app-link app-link-white mx-6"
      href="https://www.eastman.com/Pages/Legal_Information.aspx"
      target="_blank"
    >
      Copyright
    </a>
    <a
    class="app-link app-link-white"
      href="https://www.eastman.com/privacy/Pages/Overview.aspx"
      target="_blank"
    >
      Privacy
    </a>
    <v-spacer/>
    <span
      class="app-link app-link-white mr-6"
      @click="openPharma"
    >
      Eastman pharmaceutical ingredients
    </span>
    <span
      class="app-link app-link-white mr-6"
      @click="openAbout"
    >
      About
    </span>
    <router-link
      class="app-link app-link-white mr-6"
      to="/feedback"
      v-if="$msal.isAuthenticated()"
    >
      Feedback
    </router-link>
    <router-link
      class="app-link app-link-white"
      :to="contactLink"
    >
      Contact us
    </router-link>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
import linkService from '@/services/LinkServices.js'

export default {
  name: "AppFooter",
  computed: {
    ...mapState(['currentUserInfo']),
    contactLink: function () {
      return linkService.getContactLink(this.currentUserInfo)
    }
  },
  methods: {
    openPharma: function () {
      window.open(process.env.VUE_APP_PHARMA_URL)
    },
    openAbout: function () {
      window.open(process.env.VUE_APP_ABOUT_URL)
    }
  },
}
</script>

<style>

</style>